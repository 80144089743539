
body {
  font-size: 1.625rem;
  line-height: 1;
  font-family: "OpenSans", sans-serif;
  font-weight: 400;
  color: #ffffff;
}

body, * {
  padding: 0;
  margin: 0;
}

iframe {
  border: none;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Bold.eot");
  src: url("./fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/OpenSans-Bold.woff2") format("woff2"), url("./fonts/OpenSans-Bold.woff") format("woff"), url("./fonts/OpenSans-Bold.ttf") format("truetype"), url("./fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-SemiBold.eot");
  src: url("./fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("./fonts/OpenSans-SemiBold.woff2") format("woff2"), url("./fonts/OpenSans-SemiBold.woff") format("woff"), url("./fonts/OpenSans-SemiBold.ttf") format("truetype"), url("./fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Regular.eot");
  src: url("./fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/OpenSans-Regular.woff2") format("woff2"), url("./fonts/OpenSans-Regular.woff") format("woff"), url("./fonts/OpenSans-Regular.ttf") format("truetype"), url("./fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova-Regular.eot");
  src: url("./fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Regular.woff2") format("woff2"), url("./fonts/ProximaNova-Regular.woff") format("woff"), url("./fonts/ProximaNova-Regular.ttf") format("truetype"), url("./fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova-Bold.eot");
  src: url("./fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Bold.woff2") format("woff2"), url("./fonts/ProximaNova-Bold.woff") format("woff"), url("./fonts/ProximaNova-Bold.ttf") format("truetype"), url("./fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

img, svg {
  border: none;
  display: block;
  margin: auto;
}

.s-content {
  background: #11161d;
  color: #FFFFFF;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.s-content__border {
  border: 1px solid #1f2833;
  border-radius: 10px;
  position: absolute;
}

.s-content__logo {
  position: absolute;
  background: #11161d;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.s-content__container {
  background: #151a22;
  border-radius: 10px;
  position: absolute;
}

.s-content__qr {
  position: absolute;
  border-radius: 10px;
}

.s-content__code {
  position: absolute;
  font-family: monospace;
  text-align: center;
}

.s-content__line {
  position: absolute;
  background: #1f2833;
}

.s-content__message {
  position: absolute;
}

.s-content__title {
  font-family: "ProximaNova", sans-serif;
  line-height: 1.2;
  font-weight: 700;
}

.s-content__instruction {
  display: flex;
  flex-direction: column;
}

.s-content__point {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.s-content__number {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #033bf0;
  line-height: 1.1923076923;
  font-weight: 600;
}

.s-content__text {
  font-family: "ProximaNova", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.1666666667;
}

.s-content__stream-image {
  position: absolute;
  overflow: hidden;
}

.s-content__stream-message {
  position: absolute;
}

.s-content__connected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: left;
  align-items: center;
}

.s-content__connected-img img {
  width: 100%;
  height: 100%;
}

.s-content__connected-text {
  color: #08d48b;
}

.s-content__stream-title {
  font-weight: 700;
  line-height: 1.2;
}

.s-content__stream-text {
  font-weight: 400;
  line-height: 1.3666666667;
}

@media screen and (min-aspect-ratio: 9/5) {
  .s-content__title { font-size: 5vh; }
  .s-content__text { font-size: 3vh; }
  .s-content__number {font-size: 3vh; }
}

@media screen and (min-aspect-ratio: 12/7) and (max-aspect-ratio: 9/5) {
  .s-content__title { font-size: 4.7vh; }
  .s-content__text { font-size: 2.8vh; }
  .s-content__number { font-size: 2.5vh; }
}

@media screen and (min-aspect-ratio: 12/7) {
  img, svg {
    height: 100%;
    width: 100%;
  }

  .s-content__border {
    left: 10.5vh;
    right: 10.5vh;
    top: 12vh;
    bottom: 12vh;
  }

  .s-content__logo {
    left: 19.7vh;
    width: 24.6vh;
    padding: 6vh;
  }

  .s-content:has(.s-content__stream) .s-content__logo {
    left: 23.3vh;
  }

  .s-content__container {
    left: 9.2vh;
    right: 9.2vh;
    top: 9.2vh;
    bottom: 9.2vh;
  }

  .s-content__qr {
    top: 10.5vh;
    bottom: 10.5vh;
    left: 9vh;
    width: 36.6vh;
  }

  .s-content__code {
    left: 0;
    width: 100%;
    text-align: center;
    letter-spacing: 1vh;
    font-size: 9vh;
    font-weight: bold;
    bottom: 13vh;
  }

  .s-content__line {
    width: 1px;
    top: 12vh;
    bottom: 12vh;
    left: 53vh;
  }

  .s-content__message {
    left: 61vh;
    right: 6.5vh;
    top: 10.5vh;
    bottom: 10.5vh;
  }

  .s-content__title {
    padding: 1.6vh 0 4vh 0;
  }

  .s-content__point {
    padding: 1.2vh 0;
  }

  .s-content__number {
    width: 5vh;
    height: 5vh;
    margin-right: 2vh;
  }

  .s-content__stream-image {
    top: 7.3vh;
    bottom: 0;
    left: 7.3vh;
    width: 50vh;
  }

  .s-content__stream-image img {
    height: auto;
  }
  
  .s-content__stream-message {
    left: 67.6vh;
    right: 10.5vh;
    top: 13.7vh;
    bottom: 10.5vh;
  }

  .s-content__connected {
    margin-bottom: 6vh;
  }

  .s-content__connected-img {
    height: 6.8vh;
    width: 6.8vh;
    padding-right: 2.3vh;
  }

  .s-content__connected-text {
    font-size: 3.8vh;
  }

  .s-content__stream-title {
    font-size: 5vh;
    padding-bottom: 2.2vh;
  }

  .s-content__stream-text {
    font-size: 3vh;
  }
}

@media screen and (max-aspect-ratio: 12/7) and (min-aspect-ratio: 5/4) {
  img, svg {
    height: auto;
    max-width: 100%;
  }

  .s-content__border {
    left: 6vh;
    right: 6vh;
    top: 6vh;
    bottom: 6vh;
  }

  .s-content__logo {
    left: 12vh;
    width: 20vh;
    padding: 5vh;
  }

  .s-content:has(.s-content__stream) .s-content__logo {
    left: 23.3vh;
  }

  .s-content__container {
    left: 6vh;
    right: 6vh;
    top: 6vh;
    bottom: 6vh;
  }

  .s-content__qr {
    top: 23vh;
    bottom: 23vh;
    left: 6vh;
    width: 30vh;
  }

  .s-content__code {
    left: 0;
    width: 100%;
    letter-spacing: 1vh;
    text-align: center;
    font-size: 7vh;
    font-weight: bold;
    bottom: 11.5vh;
  }

  .s-content__line {
    width: 1px;
    top: 23vh;
    bottom: 23vh;
    left: 42vh;
  }

  .s-content__message {
    left: 48vh;
    right: 6vh;
    top: 23vh;
    bottom: 23vh;
  }

  .s-content__title {
    padding: 1.4vh 0;
  }

  .s-content__point {
    padding: 1.4vh 0;
  }

  .s-content__number {
    width: 5vh;
    height: 5vh;
    margin-right: 1.8vh;
  }

  .s-content__title { font-size: 3.5vh; }
  .s-content__text { font-size: 2.8vh; }
  .s-content__number {font-size: 2.8vh; }

  .s-content__stream-image {
    top: 13vh;
    bottom: 0;
    left: 7.3vh;
    width: 50vh;
  }

  .s-content__stream-image img {
    height: 100%;
  }
  
  .s-content__stream-message {
    left: 67.6vh;
    right: 3vw;
    top: 15vw;
    bottom: 12vw;
  }

  .s-content__connected {
    margin-bottom: 6vh;
  }

  .s-content__connected-img {
    height: 6.8vh;
    width: 6.8vh;
    padding-right: 2.3vh;
  }

  .s-content__connected-text {
    font-size: 3.8vh;
  }

  .s-content__stream-title {
    font-size: 5vh;
    padding-bottom: 2.2vh;
  }

  .s-content__stream-text {
    font-size: 3vh;
  }
}

@media screen and (max-aspect-ratio: 5/4) {
  img, svg {
    max-height: 100%;
    width: auto;
  }

  .s-content__border {
    left: 6vh;
    right: 6vh;
    top: 6vh;
    bottom: 6vh;
  }

  .s-content__logo {
    width: 20vh;
    padding: 5vh;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .s-content__logo img {
    max-width: 100%;
    height: auto;
  }

  .s-content__container {
    left: 6vh;
    right: 6vh;
    top: 6vh;
    bottom: 6vh;
  }

  .s-content__qr {
    top: 5vh;
    left: 5vh;
    right: 5vh;
    height: 30vh;
  }

  .s-content__code {
    left: 5vh;
    right: 5vh;
    letter-spacing: 1vh;
    font-size: 6vh;
    font-weight: bold;
    top: 12vh;
  }

  .s-content__line {
    left: 20vw;
    right: 20vw;
    height: 1px;
    top: 40vh;
  }

  .s-content__message {
    left: 6vh;
    right: 6vh;
    top: 42vh;
    bottom: 6vh;
  }

  .s-content__title {
    padding: 1.4vh 0 4vh 0;
  }

  .s-content__point {
    padding: 1.1vh 0;
  }

  .s-content__number {
    width: 4vh;
    height: 4vh;
    margin-right: 1.8vh;
  }

  .s-content__title { font-size: 5vh; }
  .s-content__text { font-size: 2.9vh; }
  .s-content__number { font-size: 2.9vh; }

  .s-content__stream-image {
    top: 5vh;
    left: 20vw;
    right: 20vw;
    height: 35vh;
    border-bottom: 1px solid #56667E;;
  }

  .s-content__stream-image img {
    height: auto;
    width: 92%;
    max-height: unset;
  }
  
  .s-content__stream-message {
    left: 15vw;
    right: 15vw;
    top: 45vh;
    bottom: 5vh;
  }

  .s-content__connected {
    margin-bottom: 4vh;
    justify-content: center;
  }

  .s-content__connected-img {
    height: 6vh;
    width: 6vh;
    padding-right: 2.3vh;
  }

  .s-content__connected-text {
    font-size: 3.4vh;
  }

  .s-content__stream-title {
    font-size: 4.5vh;
    padding-bottom: 2.2vh;
    text-align: center;
  }

  .s-content__stream-text {
    font-size: 2.7vh;
    text-align: center;
  }
}

@media screen and (min-aspect-ratio: 1) and (max-aspect-ratio: 5/4) {
  .s-content__title { font-size: 5vh; }
  .s-content__text { font-size: 2.9vh; }
  .s-content__number { font-size: 2.9vh; }
}

@media screen and (min-aspect-ratio: 8/9) and (max-aspect-ratio: 1) {
  .s-content__title { font-size: 4vh; }
  .s-content__text { font-size: 2.2vh; }
  .s-content__number { font-size: 2.2vh; }

  .s-content__stream-title { font-size: 4.2vh; }
  .s-content__stream-text { font-size: 2.5vh; }
  .s-content__stream-message {
    left: 14vw;
    right: 14vw;
  }
  .s-content__stream-image {
    left: 15vw;
    right: 15vw;
  }
}

@media screen and (min-aspect-ratio: 4/5) and (max-aspect-ratio: 8/9) {
  .s-content__title { font-size: 3.5vh; }
  .s-content__text { font-size: 2vh; }
  .s-content__number { font-size: 2vh; }

  .s-content__stream-title { font-size: 4.0vh; }
  .s-content__stream-text { font-size: 2.2vh; }
  .s-content__stream-message {
    left: 11vw;
    right: 11vw;
  }
  .s-content__stream-image {
    left: 12vw;
    right: 12vw;
  }
}

@media screen and (min-aspect-ratio: 6/9) and (max-aspect-ratio: 8/9) {
  .s-content__title { font-size: 3vh; }
  .s-content__text { font-size: 1.7vh; }
  .s-content__number { font-size: 1.7vh; }

  .s-content__stream-title { font-size: 3.8vh; }
  .s-content__stream-text { font-size: 2.0vh; }
  .s-content__stream-message {
    left: 7vw;
    right: 7vw;
  }
  .s-content__stream-image {
    left: 8vw;
    right: 8vw;
  }
}

@media screen and (max-aspect-ratio: 6/9) {
  img, svg {
    max-height: 100%;
    width: 100%;
  }

  .s-content__border {
    left: 6vw;
    right: 6vw;
    top: 10vw;
    bottom: 6vw;
  }

  .s-content__logo {
    width: 30vw;
    padding: 2vw 10vw;
  }
  .s-content__logo img {
    max-width: 100%;
    height: auto;
  }

  .s-content__container {
    left: 6vw;
    right: 6vw;
    top: 6vw;
    bottom: 6vw;
  }

  .s-content__qr {
    top: 10vw;
    left: 13vw;
    right: 13vw;
    height: 50vw;
  }

  .s-content__code {
    left: 0;
    width: 100%;
    letter-spacing: 1vh;
    text-align: center;
    font-size: 10vw;
    font-weight: bold;
    top: 19vw;
  }

  .s-content__line {
    left: 13vw;
    right: 13vw;
    height: 1px;
    top: 67vw;
  }

  .s-content__message {
    left: 6vw;
    right: 6vw;
    top: 77vw;
    bottom: 6vw;
  }

  .s-content__title {
    padding: 1.2vh 0;
  }

  .s-content__point {
    padding: 1.2vh 0;
  }

  .s-content__number {
    width: 4vh;
    height: 4vh;
    margin-right: 1.8vh;
  }

  .s-content__title { font-size: 3vh; }
  .s-content__text { font-size: 1.7vh; }
  .s-content__number { font-size: 1.7vh; }

  .s-content__stream-image {
    top: 10vh;
    left: 10vw;
    right: 10vw;
    height: 35vh;
    border-bottom: 1px solid #56667E;;
  }

  .s-content__stream-image img {
    height: auto;
    width: 90%;
    max-height: unset;
  }
  
  .s-content__stream-message {
    left: 9vw;
    right: 9vw;
    top: 50vh;
    bottom: 5vh;
  }

  .s-content__connected {
    margin-bottom: 4vh;
  }

  .s-content__connected-img {
    height: 5vh;
    width: 5vh;
  }

  .s-content__connected-text {
    font-size: 3vh;
  }

  .s-content__stream-title {
    font-size: 3.8vh;
    padding-bottom: 2.2vh;
  }

  .s-content__stream-text {
    font-size: 2.1vh;
  }
}

@media screen and (min-aspect-ratio: 3/9) and (max-aspect-ratio: 6/9) {
  .s-content__title {
    padding: 1.4vh 0 4vh 0;
  }

  .s-content__point {
    padding: 1.1vh 0;
  }
  .s-content__title { font-size: 3.2vh; }
  .s-content__text { font-size: 1.8vh; }
  .s-content__number { font-size: 1.8vh; }

  .s-content__stream-title { font-size: 3.5vh; }
  .s-content__stream-text { font-size: 1.9vh; }
  .s-content__stream-message {
    left: 6vw;
    right: 6vw;
  }
  .s-content__stream-image {
    top: 10vh;
    left: 6vw;
    right: 6vw;
    height: 30vh;
  }
}

@media screen and (max-aspect-ratio: 3/9) {
  .s-content__title {
    padding: 2vh 0;
  }

  .s-content__point {
    padding: 2vh 0;
  }

  .s-content__title { font-size: 3.7vh; }
  .s-content__text { font-size: 2.2vh; }
  .s-content__number { font-size: 2.2vh; }

  .s-content__stream-title { font-size: 3.5vh; }
  .s-content__stream-text { font-size: 1.9vh; }
  .s-content__stream-message {
    left: 4vw;
    right: 4vw;
  }
  .s-content__stream-image {
    top: 15vh;
    left: 4vw;
    right: 4vw;
    height: 25vh;
  }
}

.s-presentation__content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
}
